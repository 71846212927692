import { IAction } from "../../schema";
import {
  IGetProfile,
  IVerifyProfile,
  IRegisterProfile,
  userConst,
  IInvalidateToken,
  IResendVerificationEmail,
  IAddEmail,
  IReauth,
  IUpdateProfile,
  ITrackSignup,
  IRegisterRequest,
  loginWithMagicLinkRequestAction,
  ILoginWithMagicLinkRequest,
  ICheckMagicLinkAuthStatusRequest,
  IResendMagicLinkRequest,
} from "../actions/user";
import { combineReducers } from "redux";

const getProfileReducer = (
  state: IGetProfile | any = {},
  action: IAction<IGetProfile>
): IGetProfile => {
  const { type, payload } = action;

  if (type === userConst.FETCH_PROFILE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const verifyProfileReducer = (
  state: IVerifyProfile = {},
  action: IAction<IVerifyProfile>
): IVerifyProfile => {
  const { type, payload } = action;

  if (type === userConst.VERIFY_PROFILE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", IsPosted: payload.IsPosted };
      default:
        return state;
    }
  } else return state;
};

const registerProfileReducer = (
  state: IRegisterProfile = {},
  action: IAction<IRegisterProfile>
): IRegisterProfile => {
  const { type, payload } = action;

  if (type === userConst.REGISTER_PROFILE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return {
          ...state,
          state: "error",
          error: payload.error,
          ErrorCode: payload.ErrorCode,
        };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const invalidateTokenReducer = (
  state: IInvalidateToken = {},
  action: IAction<IInvalidateToken>
): IInvalidateToken => {
  const { type, payload } = action;

  if (type === userConst.INVALIDATE_TOKEN) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const resendEmailVerificationReducer = (
  state: IResendVerificationEmail = {},
  action: IAction<IResendVerificationEmail>
): IResendVerificationEmail => {
  const { type, payload } = action;

  if (type === userConst.RESEND_VERIFICATION_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", IsPosted: payload.IsPosted };
      default:
        return state;
    }
  } else return state;
};

const addEmailReducer = (
  state: IAddEmail = {},
  action: IAction<IAddEmail>
): IAddEmail => {
  const { type, payload } = action;

  if (type === userConst.ADD_EMAIL) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", IsPosted: payload.IsPosted };
      default:
        return state;
    }
  } else return state;
};

const reauthAccountReducer = (
  state: IReauth = {},
  action: IAction<IReauth>
): IReauth => {
  const { type, payload } = action;

  if (type === userConst.REAUTH_ACCOUNT) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const updateProfileReducer = (
  state: IUpdateProfile = {},
  action: IAction<IUpdateProfile>
): IUpdateProfile => {
  const { type, payload } = action;

  if (type === userConst.UPDATE_PROFILE) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", error: payload.error };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const trackSignupReducer = (
  state: ITrackSignup = {},
  action: IAction<ITrackSignup>
): ITrackSignup => {
  const { type, payload } = action;

  if (type === userConst.TRACK_SIGNUP) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error" };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const signupRequestReducer = (
  state: IRegisterRequest = {},
  action: IAction<IRegisterRequest>
): IRegisterRequest => {
  const { type, payload } = action;

  if (type === userConst.REGISTER_REQUEST_LEAD) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error" };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const loginWithMagicLinkRequestReducer = (
  state: ILoginWithMagicLinkRequest = {},
  action: IAction<ILoginWithMagicLinkRequest>
): ILoginWithMagicLinkRequest => {
  const { type, payload } = action;

  if (type === userConst.LOGIN_MAGIC_LINK) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error" };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const resendMagicLinkRequestReducer = (
  state: IResendMagicLinkRequest = {},
  action: IAction<IResendMagicLinkRequest>
): IResendMagicLinkRequest => {
  const { type, payload } = action;

  if (type === userConst.RESEND_MAGIC_LINK) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error" };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

const checkMagicLinkAuthStatusRequestReducer = (
  state: ICheckMagicLinkAuthStatusRequest = {},
  action: IAction<ICheckMagicLinkAuthStatusRequest>
): ICheckMagicLinkAuthStatusRequest => {
  const { type, payload } = action;

  if (type === userConst.CHECK_MAGIC_LINK_AUTH_STATUS) {
    switch (payload.state) {
      case "loading":
        return { ...state, state: "loading" };
      case "error":
        return { ...state, state: "error", ...payload };
      case "success":
        return { ...state, state: "success", ...payload };
      default:
        return state;
    }
  } else return state;
};

export const rootUserReducer = combineReducers({
  profile: getProfileReducer,
  verifyProfile: verifyProfileReducer,
  registerProfile: registerProfileReducer,
  invalidateToken: invalidateTokenReducer,
  resendEmailVerification: resendEmailVerificationReducer,
  addEmail: addEmailReducer,
  reauthAccount: reauthAccountReducer,
  updateProfile: updateProfileReducer,
  trackSignup: trackSignupReducer,
  signupRequest: signupRequestReducer,
  loginWithMagicLinkRequest: loginWithMagicLinkRequestReducer,
  checkMagicLinkAuthStatusRequest: checkMagicLinkAuthStatusRequestReducer,
  resendMagicLinkRequest: resendMagicLinkRequestReducer,
});

export interface IUser {
  profile: IGetProfile;
  verifyProfile: IVerifyProfile;
  registerProfile: IRegisterProfile;
  invalidateToken: IInvalidateToken;
  resendEmailVerification: IResendVerificationEmail;
  addEmail: IAddEmail;
  reauthAccount: IReauth;
  updateProfile: IUpdateProfile;
  trackSignup: ITrackSignup;
  signupRequest: IRegisterRequest;
  loginWithMagicLinkRequest: ILoginWithMagicLinkRequest;
  checkMagicLinkAuthStatusRequest: ICheckMagicLinkAuthStatusRequest;
  resendMagicLinkRequest: IResendMagicLinkRequest;
}
